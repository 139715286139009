import { uploadFileCallback } from '@helpers/content_helper';
import Box from '@mui/material/Box';
import { Typography, Upload } from 'antd';

export const QuestionMediaList: React.FC = () => {
	const { Title } = Typography;

	return (
		<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
			<Title level={5}>Question Media Upload</Title>
			<Upload.Dragger
				name="file"
				customRequest={uploadFileCallback({ usecase: 'question_media' })}
				maxCount={10}
				multiple={true}
				itemRender={(item: any, file: any) => {
					return (
						<div>
							<p>{item}</p>
							<p className="text-sm red-500" style={{ color: 'darkblue' }}>
								{file?.originFileObj?.url}
							</p>
						</div>
					);
				}}
			>
				<p className="ant-upload-text">Drag & drop a file in this area</p>
			</Upload.Dragger>
		</Box>
	);
};
