import { FC, useState } from 'react';
import axios from 'axios';

import { Button, TextField } from '@mui/material';
import ToastHelper from '@helpers/toast_helper';
import { MAIN_API_SERVER } from '../../constants';

const SubscriptionTasks: FC = () => {
	const [subsId, setSubsId] = useState('');
	const [loading, setLoading] = useState(false);

	const pauseSubs = async () => {
		if (!subsId) {
			new ToastHelper().showError('Please fill all fields');
			return;
		}

		try {
			setLoading(true);
			await axios.post(`${MAIN_API_SERVER}/api/subscription/pause`, { subscription_id: subsId });
		} catch (error) {
			new ToastHelper().showError('Failed to pause subscription');
		} finally {
			setLoading(false);
		}
	};

	const cancelSubs = async () => {
		if (!subsId) {
			new ToastHelper().showError('Please fill all fields');
			return;
		}

		try {
			setLoading(true);
			await axios.post(`${MAIN_API_SERVER}/api/subscription/cancel`, { subscription_id: subsId });
		} catch (error) {
			new ToastHelper().showError('Failed to cancel subscription');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			<div style={{ fontSize: '30px', fontWeight: 'bold', color: '#000', marginBottom: '30px' }}>Create Payment Link</div>

			<TextField label="Subscription ID" required value={subsId} onChange={(e) => setSubsId(e.target.value)} />

			<Button variant="contained" disabled={loading} style={{ marginTop: '20px' }} onClick={pauseSubs}>
				Pause Subscription
			</Button>

			<Button variant="contained" disabled={loading} style={{ marginTop: '20px' }} onClick={cancelSubs}>
				Cancel Subscription
			</Button>
		</div>
	);
};

export default SubscriptionTasks;
