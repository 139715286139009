import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';
import useSeriesData from '@hooks/useSeriesData';

export const ModulesListEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		control,
		formState: { errors },
	} = useForm();

	const seriesList = useSeriesData();
	const [selectedSeries, setSelectedSeries] = useState();

	const seriesId = queryResult?.data?.data.series_id;

	useEffect(() => {
		if (seriesId) {
			const seriesItem = seriesList?.filter((e: any) => e.id === seriesId)[0];
			setSelectedSeries(seriesItem);
		}
	}, [seriesId, seriesList]);

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('name', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.name}
					error={!!errors.name}
					helperText={errors.name?.message ? `${errors.name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Name"
					InputLabelProps={{ shrink: true }}
					name="name"
				/>
				<Controller
					control={control}
					name="type"
					rules={{
						required: 'Required',
					}}
					defaultValue={'pitstop'}
					render={({ field }) => (
						<Autocomplete
							options={['pitstop', 'practice']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Type'}
									margin="normal"
									variant="outlined"
									error={!!errors.type}
									helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>

				{/* <TextField
          {...register('series_id', {
            required: 'Required',
            valueAsNumber: true,
          })}
          defaultValue={queryResult?.data?.data.series_id}
          error={!!errors.series_id}
          helperText={
            errors.series_id?.message
              ? `${errors.series_id?.message}`
              : undefined
          }
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="SeriesId"
          name="series_id"
        /> */}
				{seriesList && selectedSeries ? (
					<Controller
						control={control}
						name="series_id"
						render={({ field }) => (
							<Autocomplete
								// {...autocompleteProps}
								{...field}
								options={seriesList}
								onChange={(_, value) => {
									field.onChange(value?.uuid);
								}}
								getOptionLabel={(item) => {
									return seriesList?.find((p: any) => p?.uuid?.toString() === item?.uuid?.toString())?.name ?? '';
								}}
								isOptionEqualToValue={(option, value) =>
									value === undefined || option?.uuid?.toString() === (value?.uuid ?? value)?.toString()
								}
								defaultValue={selectedSeries}
								renderInput={(params) => (
									<TextField
										{...params}
										// defaultValue={seriesName}
										label="series_id"
										margin="normal"
										variant="outlined"
										error={!!errors?.series_id}
										helperText={errors.series_id?.message ? `${errors.series_id?.message}` : undefined}
										required
									/>
								)}
							/>
						)}
					/>
				) : null}
				<Controller
					control={control}
					name="difficulty_type"
					defaultValue={null}
					render={({ field }) => (
						<Autocomplete
							options={['VIP', 'NON_VIP', 'HOTS', 'None']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Difficulty Type'}
									margin="normal"
									variant="outlined"
									error={!!errors.difficulty_type}
									helperText={errors.difficulty_type?.message ? `${errors.difficulty_type?.message}` : undefined}
								/>
							)}
						/>
					)}
				/>
				<TextField
					{...register('info')}
					defaultValue={queryResult?.data?.data.info}
					error={!!errors.info}
					helperText={errors.info?.message ? `${errors.info?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Info"
					name="info"
				/>
				<Controller
					control={control}
					name="lang"
					rules={{
						required: 'Required',
					}}
					defaultValue={queryResult?.data?.data.lang}
					render={({ field }) => (
						<Autocomplete
							options={['he', 'en', 'hi', 'all']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Lang'}
									margin="normal"
									variant="outlined"
									error={!!errors.lang}
									helperText={errors.lang?.message ? `${errors.lang?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<TextField
					{...register('description')}
					defaultValue={queryResult?.data?.data.description}
					error={!!errors.description}
					helperText={errors.description?.message ? `${errors.description?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Description"
					name="description"
				/>
				<TextField
					{...register('ref_order', {
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data.ref_order}
					error={!!errors.ref_order}
					helperText={errors.ref_order?.message ? `${errors.ref_order?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="RefOrder"
					name="ref_order"
				/>
			</Box>
		</Edit>
	);
};
