import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

export const SeriesEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		control,
		getValues,
		setValue,
		formState: { errors },
	} = useForm();

	const [checked, setChecked] = useState<boolean | null>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	useEffect(() => {
		setChecked(queryResult?.data?.data?.is_guided_completed);
	}, [queryResult?.data?.data?.is_guided_completed]);

	return (
		<Edit
			saveButtonProps={{
				...saveButtonProps,
				onClick: (e) => {
					const values = getValues();

					if (typeof values.classes === 'string') {
						const spls = values.classes
							.split(',')
							.filter((val) => val.trim().length > 0)
							.map((val) => String(val.trim()));
						setValue('classes', spls);
					}

					saveButtonProps.onClick(e);
				},
			}}
		>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('title', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.title}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Title"
					InputLabelProps={{ shrink: true }}
					name="title"
				/>
				<TextField
					{...register('name')}
					defaultValue={queryResult?.data?.data.name}
					error={!!errors.name}
					helperText={errors.name?.message ? `${errors.name?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Name"
					name="name"
				/>
				<TextField
					{...register('author')}
					defaultValue={queryResult?.data?.data.author}
					error={!!errors.author}
					helperText={errors.author?.message ? `${errors.author?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Author"
					name="author"
				/>
				<TextField
					{...register('parent_id', {
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data.parent_id}
					error={!!errors.parent_id}
					helperText={errors.parent_id?.message ? `${errors.parent_id?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Parent Series Id"
					name="parent_id"
					type="number"
				/>
				<TextField
					{...register('classes')}
					defaultValue={queryResult?.data?.data.classes}
					error={!!errors.classes}
					helperText={errors.classes?.message ? `${errors.classes?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Classes"
					name="classes"
				/>
				<TextField
					{...register('ref_order', {
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data.ref_order}
					error={!!errors.ref_order}
					helperText={errors.ref_order?.message ? `${errors.ref_order?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Ref Order"
					name="ref_order"
					type="number"
				/>
				<TextField
					{...register('description')}
					defaultValue={queryResult?.data?.data.description}
					error={!!errors.description}
					helperText={errors.description?.message ? `${errors.description?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Description"
					name="description"
				/>
				<Controller
					control={control}
					name="lang"
					rules={{
						required: 'Required',
					}}
					defaultValue={queryResult?.data?.data.lang}
					render={({ field }) => (
						<Autocomplete
							options={['he', 'en', 'hi', 'all']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Lang'}
									margin="normal"
									variant="outlined"
									error={!!errors.lang}
									helperText={errors.lang?.message ? `${errors.lang?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('is_original')} defaultChecked={queryResult?.data?.data?.is_original} />}
						label="Is original?"
					/>
				</FormGroup>
				{checked !== null ? (
					<FormGroup sx={{ marginTop: '16px' }}>
						<FormControlLabel
							control={<Switch checked={checked} {...register('is_guided_completed')} onChange={handleChange} />}
							label="is guided completed"
						/>
					</FormGroup>
				) : null}
			</Box>
		</Edit>
	);
};
