import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { MAIN_API_SERVER } from './../../constants';
import Autocomplete from '@mui/material/Autocomplete';

export const SeriesCreate: React.FC = () => {
	const [options, setOptions] = useState<string[]>([]);

	const {
		saveButtonProps,
		register,
		control,
		getValues,
		setValue,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		fetchOptions();
	}, []);

	const fetchOptions = async () => {
		try {
			const response = await axios.get(`${MAIN_API_SERVER}/api/panel/handlers`);
			const fetchedOptions = response?.data?.data;
			const handlers = fetchedOptions.map((handler: any) => handler?.alias);
			setOptions(handlers);
		} catch (error) {
			console.error('Error fetching options:', error);
		}
	};

	return (
		<Create
			saveButtonProps={{
				...saveButtonProps,
				onClick: (e) => {
					const values = getValues();

					if (typeof values.classes === 'string') {
						const spls = values.classes
							.split(',')
							.filter((val) => val.trim().length > 0)
							.map((val) => String(val.trim()));
						setValue('classes', spls);
					}

					saveButtonProps.onClick(e);
				},
			}}
		>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('name', {
						required: 'Required',
					})}
					error={!!errors.name}
					helperText={errors.name?.message ? `${errors.name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Name"
					name="name"
					autoFocus
				/>
				<TextField
					{...register('title', {
						required: 'Required',
					})}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Title"
					name="title"
				/>
				<TextField
					{...register('parent_id', {
						valueAsNumber: true,
					})}
					error={!!errors.parent_id}
					helperText={errors.parent_id?.message ? `${errors.parent_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Parent Series Id"
					name="parent_id"
					type="number"
				/>
				<TextField
					{...register('ref_order', {
						valueAsNumber: true,
					})}
					error={!!errors.ref_order}
					helperText={errors.ref_order?.message ? `${errors.ref_order?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Ref Order"
					name="ref_order"
					type="number"
				/>
				<TextField
					{...register('classes')}
					error={!!errors.classes}
					helperText={errors.classes?.message ? `${errors.classes?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Classes"
					name="classes"
				/>
				<TextField
					{...register('description')}
					error={!!errors.description}
					helperText={errors.description?.message ? `${errors.description?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Description"
					name="description"
				/>
				<TextField
					{...register('image_link')}
					error={!!errors.image_link}
					helperText={errors.image_link?.message ? `${errors.image_link?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Image Link"
					name="image_link"
				/>
				<TextField
					{...register('area')}
					error={!!errors.area}
					helperText={errors.area?.message ? `${errors.area?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Area"
					name="area"
				/>
				<Controller
					control={control}
					name="author"
					rules={{
						required: 'Required',
					}}
					render={({ field }) => (
						<Autocomplete
							options={options}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Author'}
									margin="normal"
									variant="outlined"
									error={!!errors.author}
									helperText={errors.author?.message ? `${errors.author?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="lang"
					rules={{
						required: 'Required',
					}}
					defaultValue={'he'}
					render={({ field }) => (
						<Autocomplete
							options={['he', 'en', 'hi', 'all']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Lang'}
									margin="normal"
									variant="outlined"
									error={!!errors.lang}
									helperText={errors.lang?.message ? `${errors.lang?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('is_original')} />} label="Is Original?" />
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('is_guided_completed')} />} label="Is guided completed" />
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('full_chapter_uploaded')} />} label="Full chapter uploaded" />
				</FormGroup>
			</Box>
		</Create>
	);
};
