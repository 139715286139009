import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DeleteButton, EditButton, List, useDataGrid } from '@refinedev/mui';
import React from 'react';

export const BookList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 150,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'title',
			headerName: 'title',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'display_name',
			headerName: 'display_name',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'author',
			headerName: 'author',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'edition',
			headerName: 'edition',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'uuid',
			headerName: 'uuid',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'book_type',
			headerName: 'BookType',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'is_default',
			headerName: 'isDefault',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'is_super_default',
			headerName: 'isSuperDefault',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'class_visibility',
			headerName: 'classVisibility',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'class_code',
			headerName: 'ClassCode',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'board_id',
			headerName: 'board_id',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'images',
			headerName: 'Images',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return (row.images || []).map((image: any, index: number) => {
					return <img src={image} alt={`Image-${row.id}-${index}`} />;
				});
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				return (
					<>
						<EditButton hideText recordItemId={row.id} />
						<DeleteButton hideText recordItemId={row.id} />
					</>
				);
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<any>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};
